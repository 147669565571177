import React from 'react'

// CSS
import { StyledImg, BlueStripe, H2, Content} from 'components/StyledComponents'

// Third Party
import parse from 'html-react-parser'

const ContentWithImage = ({ className, content, noTitle }) => (
  <div className={`container ${className ? `${className}` : ``}`}>
    <div className="row">
      <div className="col-12 col-lg-6">
        <StyledImg fluid={content.image.localFile.childImageSharp.fluid} alt="" />
      </div>
      <div className="col-12 col-lg-6 pt-4 pt-lg-0">
        {!noTitle && (
          <>
            <BlueStripe className="color-background-main mb-2" />
            <H2 className="font-size-xxl pb-3 font-weight-xl">{content.title}</H2>
          </>
        )}
        <Content>
          {parse(content.content)}
        </Content>
      </div>
    </div>
  </div>
)

export default ContentWithImage