import React from 'react'

// CSS
import { StyledCustomLink, StyledImg, P, H2, Image } from 'components/StyledComponents'

// Third Party
import styled from 'styled-components'
import _ from 'lodash'

// Images
import blueCheck from 'img/blue_check.svg'

const SpecialistImgContainer = styled.div`
  height: 150px;
`

const SpecialistWrapper = styled.div`
  max-width: 323px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  top: -180px;

  ${StyledImg} {
    width: 112px;
    height: 120px;
    position: absolute !important;
    bottom: 0;
    left: 30px;
  }

  @media (max-width: 991px) {
    top: 0;
    margin-top: 50px;
  }
`

const Specialist = ({ className, specialist }) => (
  <SpecialistWrapper className={`d-flex flex-wrap color-background-light justify-content-center position-relative ${className ? `${className}` : ``}`}>
    <SpecialistImgContainer className="d-flex justify-content-end position-relative px-3 pt-3">
      <StyledImg fluid={specialist.image_group[Math.round(Math.random())].image.localFile.childImageSharp.fluid} />
      <P className="col-7 pt-2 font-size-ms font-weight-xl text-center">{specialist.text}</P>
    </SpecialistImgContainer>
    <StyledCustomLink hover to={specialist.cta_1.url} className="w-100 mx-3 mb-1 text-center color-background-contrast">
      <P className="color-text-light font-weight-xl">{specialist.cta_1.title}</P>
    </StyledCustomLink>
    <StyledCustomLink hover to={specialist.cta_2.url} className="w-100 mx-3 mt-2 mb-4 text-center color-background-main">
      <P className="color-text-light font-weight-xl">{specialist.cta_2.title}</P>
    </StyledCustomLink>
    <div className="w-100 d-flex flex-wrap justify-content-center pt-3 pb-4 color-background-secondary">
      <H2 className="font-weight-xl pb-3 font-size-m">Waarom Kooij Autoschade & Onderhoud</H2>
      {_.map(specialist.why, (item, index) => (
        <P className="w-100 pl-5" key={index}>
          <Image className="pr-2" src={blueCheck} alt="" />
          {item.why_point}
        </P>
      ))}
    </div>
  </SpecialistWrapper>
)

export default Specialist