import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// CSS
import styled from 'styled-components'
import { Content, H2, P, BlueStripe } from 'components/StyledComponents'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import TitleBannerImage from 'components/TitleBannerImage'
import Specialist from 'components/Specialist'
import ContentWithImage from 'components/ContentWithImage'

// Third Party
import parse from 'html-react-parser'
import _ from 'lodash'

const Quote = styled.div`

`

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
  min-height: 400px;
  max-width: 325px;
`

const ImageText = styled.div`
  left: 70px;
  bottom: 40px;

  @media (max-width: 576px) {
    left: 25%;
  }
`

const OverOns = ({ pageContext }) => {
  const {
    overOns: {
      title,
      acf: {
        banner_image: {
          localFile: {
            childImageSharp: {
              fluid: bannerImage1
            }
          }
        },
        over_ons: overOns,
        specialist,
        text_and_image: textAndImage,
        quote,
        h1_tag: h1Tag
      },
      yoast_meta: yoast
    },
    medewerkers: {
      edges: medewerkerList
    }
  } = useStaticQuery(graphql`{
    overOns: wordpressPage(wordpress_id: {eq: 15}) {
      title
      acf {
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        over_ons
        specialist {
          cta_1 {
            target
            title
            url
          }
          cta_2 {
            target
            title
            url
          }
          text
          image_group {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          why {
            why_point
          }
        }
        text_and_image {
          content
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        quote {
          text
          from
        }
        h1_tag
      }
      yoast_meta {
        name
        content
        property
      }
    }
  medewerkers: allWordpressWpMedewerker(sort: {fields: modified}) {
    edges {
      node {
        acf {
          name_and_function
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
  }`)

  return (
    <Layout subFooter>
      <SEO yoast={{ meta: yoast }} />
      <h1 className="d-none">{h1Tag}</h1>
      <TitleBannerImage image={bannerImage1} pageContext={pageContext} breadCrumbTitle={title} title={title} className="row position-relative d-flex align-items-end" />

      <div className="container pt-5">
        <div className="row">
          <div className="col-12 col-lg-7">
            <Content>
              {parse(overOns)}
            </Content>
          </div>
          <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end">
            <Specialist specialist={specialist} />
          </div>
        </div>
      </div>

      <div className="container pl-5">
        <div className="row">
          <Quote className="pl-0 pl-lg-5 pt-5 pt-lg-0 col-lg-7 col-12">
            <H2 className="font-weight-xl font-size-mxm color-main">{quote.text}</H2>
            <P className="font-weight-xl font-size-sm">{`- ${quote.from}`}</P>
          </Quote>
        </div>
      </div>
      

      <ContentWithImage noTitle className="py-5" content={textAndImage} />

      <div className="container pb-5 pt-3 mb-5">
        <BlueStripe className="color-background-main mb-2" />
        <H2 className="font-size-xxl font-weight-xl pb-4">Ons team</H2>

        <div className="row">
          {_.map(medewerkerList, (med, index) => (
            <div key={index} className="position-relative col-lg-4 col-sm-6 col-12 d-flex justify-content-center p-4">
              <StyledImg fluid={med.node.acf.image.localFile.childImageSharp.fluid} alt="" />
              <ImageText className="position-absolute">
                <BlueStripe width={46} className="color-background-main mb-1" />
                <P className="color-text-light font-weight-xl font-size-ms">{med.node.acf.name_and_function}</P>
              </ImageText>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default OverOns